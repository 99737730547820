import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

// import IconImage from '../images/icon-image'
import HamburgerMenuNav from "./hamburger-menu-nav"

import throttle from "../../utils/throttle"

const showBgNavBar = (limit = 200) => {
  return window.scrollY > limit
}

const Header = ({ siteTitle }) => {
  const [bgFilled, setBgFilled] = useState(false)
  const setBgNavByY = throttle(() => {
    setBgFilled(showBgNavBar())
  }, 15)

  useEffect(() => {
    window.addEventListener("scroll", setBgNavByY)

    return () => {
      window.removeEventListener("scroll", setBgNavByY)
    }
  }, [setBgNavByY])

  return (
    <header>
      <div className={`shown ${bgFilled ? "expanded" : ""}`}>
        <div>
          {/* <span>{siteTitle}</span> */}
        </div>
      </div>
      <div className={`site-header ${bgFilled ? "" : ""}`}>
        <span className="site-logo hoverable">
          <button>Sign Up</button>
        </span>

        <HamburgerMenuNav clickHook={() => {}}>
          <div className="hoverable-parent">
            <a className="nav-text hoverable" href="#plans">
              Plans
            </a>
          </div>

          <div className="hoverable-parent">
            <a className="nav-text hoverable" href="#benefits">
              Benefits
            </a>
          </div>

          <div className="hoverable-parent">
            <a className="nav-text hoverable" href="#learn">
              Learn
            </a>
          </div>
          <div className="seperator" />
          <div className="hoverable-parent">
            <a className="nav-text hoverable" href="#learn">
              Sign In
            </a>
          </div>
        </HamburgerMenuNav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
