import React from "react"
import PropTypes from "prop-types"

import throttle from '../../utils/throttle'

const useSmallScreen = (minWidth=500, defaultSmallScreen=false) => {
  const [smallScreen, setSmallScreen] = React.useState(defaultSmallScreen)
  const resizeScreenHandler = throttle(() => {
    setSmallScreen(window.screen.width <= minWidth)
  })

  React.useEffect(() => {
    setSmallScreen(window.screen.width <= minWidth)
  }, [minWidth])

  React.useEffect(() => {
    window.addEventListener('resize', resizeScreenHandler)

    return () => {
      window.removeEventListener('resize', resizeScreenHandler)
    }
  }, [resizeScreenHandler])

  return [smallScreen]
}

const HamburgerMenuNav = ({ clickHook, children }) => {
  const [rotated, setRotated] = React.useState(false)
  const [phoneScreen] = useSmallScreen()

  const toggleRotated = () => { setRotated(!rotated); if (clickHook) clickHook() }
  React.useEffect(() => setTimeout(() => setRotated(window.screen.width > 500), 250), [])

  return (
    <div className="hamburger__menu-parent">
      <span className={`hamburger__menu-nav_items ${rotated ? "rotated" : ""}`}>
        {phoneScreen && 
        <div className="phone__nav">
          {children}
        </div>
        }
        {!phoneScreen &&
        <div className="center-nav" style={{ display: "inline-flex" }}>
          {children}
        </div>
        }
      </span>
      <div
        className={`hamburger__menu${rotated ? " rotated" : ""}`}
        onClick={toggleRotated}
      >
        <span className={"menu__item-t"} />
        <span className={"menu__item-m"} />
      </div>
    </div>
  )
}

HamburgerMenuNav.propTypes = {
  clickHook: PropTypes.func,
}

export default HamburgerMenuNav
