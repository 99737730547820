export default (fn, threshold = 100) => {
  let last
  let timer

  return () => {
    const now = new Date()
    const timePassed = last && now.getTime() > last.getTime() + threshold

    if (timePassed) {
      clearTimeout(timer)

      timer = setTimeout(() => {
        last = now
        fn()
      }, threshold)
    } else if (!last) {
      last = now
    }
  }
}
